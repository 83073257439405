const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    // console.log(entry);
    if (entry.isIntersecting) {
      entry.target.classList.add("show");
    } else {
      //  entry.target.classList.remove("show");
    }
  });
}, { rootMargin: "0px 0px 100px 0px" });

const hiddenElements = document.querySelectorAll(".hidden");
hiddenElements.forEach((el) => observer.observe(el));


const observer2 = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    // console.log(entry);
    if (entry.isIntersecting) {
      entry.target.classList.add("showme");
    } else {
      //  entry.target.classList.remove("show");
    }
  });
}, { rootMargin: "0px 0px 100px 0px" });

const tagLine = document.querySelector ( ".header h1");
observer2.observe( tagLine);


const bannerBtn = document.querySelector(".usa-top-banner button");
bannerBtn.addEventListener("click", (event) => {
  event.preventDefault();

  const banner = document.querySelector(".usa-top-banner #banner");
  banner.classList.toggle("hide-me");

  const chevron = document.querySelector(".usa-top-banner button i");
  if (banner.classList.contains("hide-me")) {
    chevron.classList.remove("bi-chevron-up");
    chevron.classList.add("bi-chevron-down");
  }
  else {
    chevron.classList.remove("bi-chevron-down");
    chevron.classList.add("bi-chevron-up");

  }

});